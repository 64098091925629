/*******************************************************************************
variables - all global and pallete variables for project
*******************************************************************************/

:root {
  /* colors palette */
  --c-mercury: hsl(0, 0%, 90%);
  --c-celeste: hsl(0, 0%, 80%);
  --c-shady-lady: hsl(0, 0%, 65%);
  --c-stack: hsl(0, 0%, 55%);
  --c-dove-gray: hsl(0, 0%, 45%);
  --c-shark: hsl(0, 0%, 10%);

  /* font families palette */
  --font-sansSerif: "Source Sans Pro", "Roboto", "PT Sans", "Droid Sans",
    "Fira Sans", "Work Sans", "Open Sans", "Noto Sans", "Hind", "Dosis", "Cabin",
    "Verdana", "Tahoma", sans-serif;
  --font-monospace: "Input", "Office Code Pro", "Source Code Pro", "Fira Mono",
    "Inconsolata", "Monaco", "Consolas", "Lucida Console", "Liberation Mono",
    "DejaVu Sans Mono", monospace;

  /* leading size for all spacing between elements to be pretty */
  --root-lineHeight: 1.5;
  --s-leading: calc(var(--root-lineHeight) * 1rem);
  --s-leading-half: calc(var(--s-leading) * 0.5);

  /* globals */
  --s-global-br: 0.125rem;
  --s-global-border: 0.125rem;
}
